import { createConsumer } from "@rails/actioncable";
import { Cable, Channel } from "actioncable";
import axios, { AxiosInstance } from "axios";

import { ContentType } from "../types";
import { ServiceDeskFactoryParameters } from "../types/serviceDesk";

export class ChatwootClient {
  callback: ServiceDeskFactoryParameters["callback"] | undefined;

  consumer: Cable;
  subscription: Channel | undefined;

  client: AxiosInstance;
  inboxClient: AxiosInstance;
  conversationClient: AxiosInstance | undefined;
  activeConversationId: string | undefined;
  agentId: string | number | undefined;

  constructor(
    baseUrl: string,
    inboxId: string,
    websocketUrl: string,
    callback?: ServiceDeskFactoryParameters["callback"]
  ) {
    this.callback = callback;
    this.consumer = createConsumer(`${websocketUrl}/cable`);

    this.client = axios.create({
      baseURL: `${baseUrl}/public/api/v1`,
    });

    this.inboxClient = axios.create({
      baseURL: `${this.client.defaults.baseURL}/inboxes/${inboxId}`,
    });
  }

  private _createContact = async (userId: string) => {
    const { data } = await this.inboxClient.post(`/contacts`, { identifier: userId });

    return {
      contactId: data.source_id,
      pubSubToken: data.pubsub_token,
    };
  };

  createConversation = async (userId: string) => {
    const { contactId, pubSubToken } = await this._createContact(userId);

    this.subscription = this.consumer.subscriptions.create({
      channel: "RoomChannel",
      pubsub_token: pubSubToken,
    }, {
      received: this.onMessage,
    });

    const { data } = await this.inboxClient.post(`/contacts/${contactId}/conversations`, {
      custom_attributes: {},
    });

    this.conversationClient = axios.create({
      baseURL: `${this.inboxClient.defaults.baseURL}/contacts/${contactId}/conversations/${data.id}`,
    });

    this.activeConversationId = data.id;
  };

  sendMessage = async (content: string) => this.conversationClient?.post("/messages", { content });
  setResolved = async () => this.conversationClient?.post("/toggle_status", {});

  onMessage = (event: any) => {
    const { data, event: _event } = event;
    _event && console.debug({ event: _event, data: data });

    switch (_event) {
      case "conversation.created":
        return this.onConversationCreated(data);
      case "conversation.updated":
        return this.onConversationUpdated(data);
      case "message.created":
        return this.onMessageCreated(data);
      case "conversation.status_changed":
        return this.onStatusChanged(data);
      case "conversation.typing_on":
        return this.onTyping(data)(true);
      case "conversation.typing_off":
        return this.onTyping(data)(false);
    }
  };

  onConversationCreated = ({ meta }: any) => {
    const { assignee } = meta;
    this.agentId = !assignee ? 0 : assignee.id;
    this.callback?.agentJoined({ id: this.agentId! as string, nickname: this.agentId ? assignee.name : "Agent" });
  };

  onConversationUpdated = ({ meta, status, id }: any) => {
    const { assignee } = meta;

    if (this.activeConversationId !== id || !assignee || !assignee.id || status !== "open") {
      return;
    }

    if (this.agentId === assignee.id) {
      return;
    }

    this.agentId = assignee.id;
    this.callback?.agentJoined({ id: this.agentId! as string, nickname: assignee.name });
  };

  getSurveyId = (message: string) => {
    const regex = /https?:\/\/[^\s]+\/survey\/responses\/([^\s]+)/;
    const [, match] = message.match(regex) || [];
    return match;
  };

  onMessageCreated = ({ content, message_type, conversation_id }: any) => {
    if (this.activeConversationId !== conversation_id || message_type === 0) {
      return;
    }

    // Handle CSAT survey messages
    const surveyId = this.getSurveyId(content);
    if (surveyId) {
      this.callback?.sendMessageToUser({
        id: [...crypto.getRandomValues(new Uint8Array(16))].map(b => b.toString(16)).join(""),
        output: {
          generic: [{
            response_type: "user_defined",
            user_defined: {
              type: ContentType.Survey,
              surveyId: surveyId,
            },
          }],
        },
      });
      this.callback?.agentTyping(false);
      return;
    }

    this.callback?.sendMessageToUser(content);
    this.callback?.agentTyping(false);
  };

  onStatusChanged = ({ status, performer, id }: any) => {
    if (this.activeConversationId !== id) {
      return;
    }

    if (status === "resolved" && performer) {
      this.callback?.agentEndedChat();
      this.unsubscribe();
    }
  };

  onTyping = ({ conversation }: any) => (setTyping: boolean) => {
    const { id } = conversation;

    if (this.activeConversationId !== id) {
      return;
    }

    this.callback?.agentTyping(setTyping);
  };

  putCustomerSurvey = async (surveyId: string, rating?: number, feedback?: string) => {
    await this.client.put(`/csat_survey/${surveyId}`, {
      message: {
        submitted_values: {
          csat_survey_response: {
            rating: rating || 0,
            feedback_message: feedback || "",
          },
        },
      },
    });
  };

  getCustomerSurvey = async (surveyId: string) => {
    const { data } = await this.client.get(`/csat_survey/${surveyId}`);
    return data?.csat_survey_response;
  };

  unsubscribe = async () => {
    console.debug("Unsubscribing from conversation");
    await new Promise(resolve => setTimeout(resolve, 1000));
    await this.subscription?.unsubscribe();
    this.subscription = undefined;
  };
}

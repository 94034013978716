import React from "react";

import { ChatwootClient } from "./client";

import { BotMessage, Message, MessageType, UserMessage } from "../types";
import { MessageRequest, ServiceDesk, ServiceDeskFactoryParameters } from "../types/serviceDesk";

export class ChatwootServiceDeskFactory implements ServiceDesk {
  callback: ServiceDeskFactoryParameters["callback"];
  chatwoot: ChatwootClient;
  conversationHistory: React.MutableRefObject<Message[]>;

  constructor(
    { callback }: ServiceDeskFactoryParameters,
    baseUrl: string,
    inboxId: string,
    websocketUrl: string,
    conversationHistory: React.MutableRefObject<Message[]>
  ) {
    this.callback = callback;
    this.chatwoot = new ChatwootClient(baseUrl, inboxId, websocketUrl, callback);
    this.conversationHistory = conversationHistory;
  }

  getName = () => "chatwoot";

  renderUser = (type: MessageType) => {
    switch (type) {
      case MessageType.Bot:
        return "Bot";
      case MessageType.User:
        return "User";
      case MessageType.Agent:
        return "Agent";
    }
  };

  renderMessage = (message: Message) => `**[${this.renderUser(message.type)}]:** ${(message as any)?.text}`;

  startChat = async (connectMessage: any) => {
    const userId: string = connectMessage.user_id;
    await this.chatwoot.createConversation(userId.replace("anonymous_IBMuid-", ""));

    const context = this.conversationHistory.current
      ?.filter((message) => (message as UserMessage)?.message_type === "text" || (message as BotMessage)?.response_type === "text")
      ?.map(this.renderMessage)
      ?.join("\n") || "";

    await this.chatwoot.sendMessage(context);
  };

  endChat = async () => {
    await this.chatwoot.setResolved();
    await this.chatwoot.unsubscribe();
  };

  sendMessageToAgent = async (message: MessageRequest) => {
    await this.chatwoot.sendMessage(message.input.text);
  };
}
